import { v4 } from 'uuid';
import type { FeedEntry, UIComponent } from '~/services/layout/index.ts';
import type {
  AnalyticsPlayerEventNames,
  UserActionsEventNames,
  AnalyticsScreenEventNames,
  AnalyticsTapEventNames,
  AnalyticsPurchaseEventNames,
  AnalyticsSearchEventNames,
} from '~/services/analytics/events-names.ts';
import logger from '~/services/logger';
import type { RecentStoreValues } from '~/services/analytics/events-names';

const GA_USER_ID_KEY = 'zapp-ga-user-id';

export const GoogleAnalyticsManager = ({
  gaTrackingId,
  analyticsSharedVars,
  userId,
}: any): JSX.Element | null => {
  return gaTrackingId ? (
    <>
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gaTrackingId}', ${JSON.stringify({
            ...analyticsSharedVars,
            user_id: userId,
          })});
            `,
        }}
      />
    </>
  ) : null;
};

export const addGoogleTagManagerScript = (gtmTrackingId: string) => {
  if (!gtmTrackingId) return;

  const gtmId: string = 'google-tag-manager';
  const isGtmScriptExist: boolean = !!document?.getElementById(gtmId);

  if (isGtmScriptExist) return;

  (function (w: Window, d: Document, s: 'script', l: string, i: string) {
    //@ts-ignore
    w[l] = w[l] || [];
    //@ts-ignore
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement<'script'>(s);
    const dl = l != 'dataLayer' ? '&l=' + l : '';
    j.id = gtmId;
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmTrackingId);
};

export const GoogleTagManagerNoScript = ({
  gtmTrackingId,
}: any): JSX.Element | null => {
  return gtmTrackingId ? (
    <noscript>
      <iframe
        title="googletagmanager"
        src={`https://www.googletagmanager.com/ns.html?id=${gtmTrackingId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  ) : null;
};

export function getZappClientUUID() {
  try {
    const uuidKey = 'zapp-uuid';

    const currentUuid = localStorage.getItem(uuidKey);

    const uuid = currentUuid || v4();

    if (!currentUuid) localStorage.setItem(uuidKey, JSON.stringify(uuid));

    return uuid;
  } catch (error) {
    return v4();
  }
}

export type EventName =
  | AnalyticsPlayerEventNames
  | UserActionsEventNames
  | AnalyticsScreenEventNames
  | AnalyticsTapEventNames
  | AnalyticsPurchaseEventNames
  | AnalyticsSearchEventNames;

export type AnalyticsData = {
  eventName: EventName;
  trackData: unknown;
};

export const getTapCellAnalyticsData = (
  eventName: EventName,
  analyticsTrackData: any,
  UIComponent?: UIComponent,
  entry?: FeedEntry
) => {
  const {
    id: component_id,
    component_type,
    name: header_name,
    styles,
  } = UIComponent || {};

  const { id: entry_id, type, title, _entryIndex }: any = entry || {};

  return {
    eventName,
    trackData: {
      ...analyticsTrackData,
      component_type,
      component_id,
      header_name,
      cell_style: styles?.cell_plugin_configuration_id,
      item_type: type?.value,
      item_number: _entryIndex,
      item_name: title,
      item_id: entry_id,
    },
  };
};

export const getNavItemAnalyticsData = (
  eventName: EventName,
  analyticsTrackData: any,
  additionalData?: any
) => {
  try {
    const {
      version,
      build_number,
      bundle_id,
      sdk_version,
      layout_id,
      zapp_platform,
      analyticsCustomProperties,
    } = analyticsTrackData;

    return {
      eventName,
      trackData: {
        version,
        build_number,
        bundle_id,
        sdk_version,
        layout_id,
        zapp_platform,
        analyticsCustomProperties,
        ...additionalData,
      },
    };
  } catch (error: any) {
    logger.info(`getNavItemAnalyticsData: ${error.message}`);
    return {
      eventName,
      trackData: {},
    };
  }
};

type PurchaseAnalyticsData = {
  eventName: EventName;
  trackData: Record<string, any>;
};

type PurchaseAnalyticsOverrides = {
  currency?: string;
  value?: string;
  transaction_id?: number;
  payment_type?: string;
  coupon?: string;
  items?: Array<{
    item_id: string | undefined;
    item_name: string | undefined;
  }>;
};

/**
 * Retrieves purchase analytics data based on the provided event name and additional data.
 * This function constructs a structured analytics data object that includes various
 * details related to the purchase event, such as currency, total price, offer details,
 * and payment information.
 *
 * @param eventName - The name of the event for which analytics data is being tracked.
 * @param analyticsTrackData - An object containing base analytics tracking data.
 * @param additionalData - An object containing additional information relevant to the purchase,
 *                         including currency, total price, offer details, and other metadata.
 * @returns A structured object containing the event name and associated tracking data for
 *          the purchase event. If an error occurs during data retrieval, an empty
 *          analytics object is returned.
 */
export const getPurchaseAnalyticData = (
  eventName: EventName,
  analyticsTrackData: any,
  additionalData: RecentStoreValues
): PurchaseAnalyticsData => {
  try {
    // Create a general tracking data object that consolidates relevant information
    const generalTrackData = {
      ...analyticsTrackData,
      ...additionalData,
    };

    /**
     * Creates a structured analytics data object with the specified overrides.
     * This function merges the general tracking data with any provided overrides
     * specific to the event being tracked.
     *
     * @param overrides - An object containing additional properties to override
     *                    the general tracking data.
     * @returns A structured object containing the event name and the combined
     *          track data.
     */
    const createTrackData = (
      overrides: PurchaseAnalyticsOverrides
    ): PurchaseAnalyticsData => ({
      eventName,
      trackData: { ...generalTrackData, ...overrides },
    });

    return createTrackData({});
  } catch (error: any) {
    logger.info(`getPurchaseAnalyticData failed: ${error.message}`);
    return { eventName, trackData: {} };
  }
};

export const getGoogleAnalyticsUserId = () => {
  try {
    return localStorage?.getItem(GA_USER_ID_KEY) ?? '';
  } catch (error: any) {
    logger.error(`failed to get GA user id: ${error?.message}`);
    return '';
  }
};

export const setGoogleAnalyticsUserId = (id: string) => {
  try {
    localStorage?.setItem(GA_USER_ID_KEY, id);
  } catch (error: any) {
    logger.error(`failed to set GA user id: ${error?.message}`);
  }
};
